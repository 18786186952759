import lozad from 'lozad'
import {watchViewport} from 'tornis';


const animateView = ({ size, scroll, mouse, orientation }) => {
	if (scroll.changed) {
		let scrollOffset = scroll.top / (size.docY - size.y);

		scrollOffset = scrollOffset < 0 ? 0 : scrollOffset;
		scrollOffset = scrollOffset > 1 ? 1 : scrollOffset;

		document.body.style.setProperty('--scrollY', scrollOffset);
	}
};



window.onload = function() {
	const observer = lozad();
	observer.observe();
	
	watchViewport(animateView);
}








// __TORNIS.watchViewport(animateView);
